<template>
  <div class="flex-column avatar-frame-page">
    <pageHead title="设置头像框"></pageHead>
    <div class="flex-column avatar-frame-content">
      <p class="title-tips">设置你的个性头像</p>
      <div class="cut-line" color="#e7e7e7"></div>
      <div
        infinite-scroll-throttle-delay="500"
        infinite-scroll-distance="50"
        v-infinite-scroll="loadMore" class="list-ul">
        <ul
          class="flex-column">
          <li
            @click="changeSelect(item)"
            v-for="(item) in avatarFrameList"
            :key="item.avatarFrameUserId">
            <div
              class="flex-center-row avatar-box"
              :class="{avatarBoxActive: selectedId === item.avatarFrameUserId}">
              <div v-if="item.isNew || item.status === 2" class="angle-mark" :class="{haveDown: item.status === 2}">
                <span v-if="item.status === 2">已下架</span>
                <span v-else-if="item.isNew">NEW</span>
              </div>
              <img v-if="item.avatarFrameUrl" class="frame" :src="item.avatarFrameUrl" alt="frame">
              <img v-if="selectedId === item.avatarFrameUserId" class="selected" src="/images/mine/selected.png" alt="">
            </div>
            <p class="name">{{ item.avatarFrameName }}</p>
          </li>
          <li v-if="finished">
            <div class="flex-center-column avatar-box more-box">
              <span class="more">更多个性头像框</span>
              <span class="more">敬请期待</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import {getListUserAvatarFrame, updateUserAvatarFrame} from "@/api/avatarFrame";

export default {
  name: "index",
  components: {
    pageHead
  },
  data () {
    return {
      avatarFrameList: [
        // {
        //   avatarFrameId: 123456,
        //   avatarFrameName: "默认",
        //   avatarFrameUrl: "https://hx1.xuanyu.com.cn/img/Swy2Gen6rm.png",
        //   avatarFrameUserId: 123456,
        //   isNew: false,
        //   status: 2
        // },
      ],
      selectedId: null,
      paginationData: {
        pageNum: 1,
        pageSize: 20
      },
      finished: false,
    }
  },
  created () {
    this.getAvatarFrameList()
  },
  methods: {
    /**
     * todo 加载更多
     */
    loadMore () {
      console.log(111)
      if (!this.finished && this.avatarFrameList.length > 0) {
        this.loading = true
        this.paginationData.pageNum++
        this.getAvatarFrameList()
      }
    },
    /**
     * todo 获取用户得到的头像框列表
     */
    async getAvatarFrameList (type) {
      this.loading = true
      try {
        const res = await getListUserAvatarFrame(this.paginationData) || {}
        const {records} = res || []
        if (records.length < 10) {
          this.finished = true;
        }
        if (type === 'reset') {
          this.avatarFrameList = records
        } else {
          this.avatarFrameList = this.avatarFrameList.concat(records)
        }
        const useList = this.avatarFrameList.filter(item => item.isUsing) || []
        if (useList.length > 0) {
          this.selectedId = useList[useList.length - 1].avatarFrameUserId
        }
      } finally {
        // 加载状态结束
        this.loading = false;
        this.isLoaded = true
      }
    },
    setSelectedId () {

    },
    /**
     * todo 更新用户的头像框接口
     */
    async updateUserAvatarFrame (id) {
      try {
        await updateUserAvatarFrame(id)
        this.$toast({
          message: '切换成功',
          position: 'top'
        })
        console.log(id)
        this.selectedId = id
      } catch {
        this.$toast({
          type: 'warring',
          message: '切换失败'
        })
      }
    },
    // 切换选项
    changeSelect (item) {
      console.log(item)
      if (item.status === 2) {
        return this.$toast({
          message: '头像框已下架',
          position: 'top'
        })
      }
      this.updateUserAvatarFrame(item.avatarFrameUserId)
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-frame-page {
  height: 100%;
  overflow: hidden;
}

.avatar-frame-content {
  flex: 1;
  overflow: hidden;

  margin: 10px;
  padding: 22px 15px 0;
  background: #ffffff;
  border-radius: 6px;

  .list-ul {
    flex: 1;
    overflow-y: auto;
  }

  .title-tips {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    line-height: 14px;
  }

  > .cut-line {
    height: 1px;
    margin: 13.5px 0 16px;
    background: #e7e7e7;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33.3%;
      margin-bottom: 16px;

      .avatar-box {
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 100px;
        border-radius: 6px;
        border: 1px solid transparent;

        > img.frame {
          width: 68px;
          height: 68px;
        }

        > img.selected {
          position: absolute;
          right: 6px;
          bottom: 6px;
          width: 20px;
          height: 20px;
        }

        .angle-mark {
          position: absolute;
          top: 0;
          right: 0;
          padding: 0 6px;
          background-color: #EC0909;
          border-radius: 8px 8px 0 8px;
          height: 16px;
          font-size: 10px;
          color: #ffffff;
          line-height: 16px;
        }

        .haveDown {
          background-color: #cccccc;
        }
      }

      .avatarBoxActive {
        border: 1px solid #fe5800;
        box-shadow: 0 2px 8px 0 rgba(254, 88, 0, 0.40);
      }


      .more-box {
        background-color: #f6f6f6;
        border: 1px solid #f6f6f6;
        box-shadow: none;

        .more {
          padding: 0 7px;
          font-size: 12px;
          color: #999999;
          text-align: center;
          line-height: 18px;
        }
      }


      .name {
        margin: 8px 0 0;
        padding: 0;
        font-size: 12px;
        color: #000000;
        text-align: center;
      }

    }
  }
}
</style>
